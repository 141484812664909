.ProfilePic {
  width: 100%;
  margin-right: 50px;
}

.Israel {
  width: 100%;
  margin-top: 5rem;
  margin-bottom: -1em;
  padding-bottom: 0em;
}

.TreyIcon {
  height: 5rem;
}

.aStyle {
  text-decoration: none;
  color: lightgray;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.aStyle:hover {
  color: white;
}

.navBarP1 {
  color: darkgray;
  position: absolute;
  left: 9rem;
  top: -2.4rem;
  font-size: 3em;
  cursor: pointer;
}

.navBarP2 {
  color: darkgray;
  position: absolute;
  left: 19rem;
  top: -2.4rem;
  font-size: 3em;
  cursor: pointer;
}

.navBarP3 {
  color: darkgray;
  position: absolute;
  left: 31.7rem;
  top: -2.4rem;
  font-size: 3em;
  cursor: pointer;
}

.div0 {
  position: fixed;
  background-color: black;
  width: 100%;
  height: 80px;
}

.div1 {
  background-color: #242726;
  margin: 0px;
  border: 0px;
  padding: 0px 10% 150px 10%;
}

.div2 {
  background-color: #18191a;
  margin: 0px;
  border: 0px;
  padding-left: 100px;
  padding-right: 100px;
}

.div3 {
  padding-left: 100px;
  padding-right: 100px;
  background-image: url("./images/Gator2.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100%;
  background-position-y: 70px;
  background-color: #00529c;
  /* background-position-x: -10px; */
}

.div4 {
  background-color: black;
  color: black;
}

.div5 {
  position: relative;
  background-color: white;
  margin: 0px;
  border: 0px;
  /* padding-left: 30%; */
  width: 210px;
}

.header {
  margin: auto;
  padding-top: 3.125em;
  padding-bottom: 3.125em;
  text-align: center;
  color: orange;
  font-family: "Open Sans", sans-serif;
}

thead {
  font-size: 1.5rem;
}

h1 {
  font-size: 3rem;
  font-family: "Open Sans", sans-serif;
}

h2 {
  font-size: 2rem;
}

h4 {
  font-size: 2rem;
}

.kes {
  position: relative;
  top: 25px;
}

p {
  font-size: 1.5rem;
  font-family: "Open Sans", sans-serif;
}

li {
  font-size: 1.5rem;
  padding-top: 0.5rem;
  font-family: "Open Sans", sans-serif;
}

.mainList {
  padding-top: 1rem;
}

body {
  margin: 0px;
  font-family: "Open Sans", sans-serif;
}

.linkLook {
  text-decoration: none;
}

.podcastH1 {
  position: relative;
  padding-top: 200px;
  z-index: -1;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-google {
  background: #dd4b39;
  color: white;
}

.fa-github {
  background: white;
  color: black;
}

.fa {
  padding: 20px;
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}
